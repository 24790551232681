<template lang="pug">
  v-container
    base-navigation-tabs(
      :items="paths"
    )
    v-card(min-height="80vh").mt-2
      router-view
</template>
<script>
import searchDelay from '@/libs/searchDelay.extra'

const pushDelay = searchDelay(100)

export default {
  name: 'Customers',
  components: {
    tooltipBtn: () => import('@/components/extra/TooltipBtn'),
  },
  data () {
    return {
      paths: [
        { label: 'New', path: '/purchase-requests/create' },
        { label: 'List', path: '/purchase-requests' },
      ],
    }
  },
  methods: {
    routePush ({ name }) {
      if (name === this.$route.name) return
      pushDelay(() => {
        this.$router.push({ name })
      })
    },
  },
}
</script>
